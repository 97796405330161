import { Spinner } from '@patternfly/react-core'
import { range } from 'd3-array'
import { CSSProperties, useCallback, useContext } from 'react'
import MastContext from '../../../contexts/MastContext'
import { plot_color_scale_200 } from '../../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../../utils/plotly'
import Plot from '../../shared/Plot'
import useData from '../useData'

const config = { ...defaultConfig }
const style: CSSProperties = {
  width: '100%',
}

const windRoseLayout = {
  template: layoutTemplate,
  polar: {
    gridshape: 'linear',
    radialaxis: {
      angle: 45,
      tickangle: 45,
      type: 'linear',
    },
    angularaxis: {
      thetaunit: 'degrees',
      direction: 'clockwise',
      period: 6,
    },
  },
  legend: {
    x: 0.5,
    y: 1.15,
    xanchor: 'center',
    yanchor: 'bottom',
  },
}

interface PlotWindRoseProps {
  revision: number
  onRender?: () => void
}

const PlotWindRose = (props: PlotWindRoseProps) => {
  const { mast, sensors: allSensors } = useContext(MastContext)
  const tsFin = new Date(mast.dataset_end)
  const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDay() - 7)
  const { mastDataBySensor, dataIsLoading } = useData(tsIn, tsFin)
  const windDirectionSensors = allSensors.filter(s => s.type === 'WDR')

  const directionData = useCallback(
    () =>
      windDirectionSensors
        .flatMap((sensor, i) => {
          const mastData = mastDataBySensor.get(sensor.id)
          if (!mastData) {
            console.log(`Unable to find data for sensor:${sensor.label}`)
            return null
          }

          const step = 2
          const bins = range(0, 360, step)
          const histogram = bins.map(b => 0)
          let total = 0

          mastData.forEach(md => {
            const bin = Math.trunc(md.avg / step)
            histogram[bin] = histogram[bin] + 1
            total = total + 1
          })

          const sensorColor: string = plot_color_scale_200[i]

          return [
            {
              type: 'scatterpolar',
              name: `${sensor.label} - AVG`,
              r: histogram.map(i => (i / total) * 100),
              theta: bins,
              fill: 'toself',
              line: {
                color: sensorColor,
              },
            },
          ]
        })
        .filter(d => d),
    [mastDataBySensor, windDirectionSensors]
  )

  if (dataIsLoading) return null
  if (directionData().length === 0)
    return <Spinner className='pf-v5-u-m-xl' size='xl' aria-label='Loading' />

  return (
    <div style={style}>
      <Plot
        key={mast.id * props.revision}
        useResizeHandler
        data={directionData()}
        layout={windRoseLayout}
        config={config}
        style={style}
        revision={props.revision}
        onInitialized={props?.onRender}
        onRelayout={props?.onRender}
      />
    </div>
  )
}
export default PlotWindRose
