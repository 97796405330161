import { useQuery } from '@tanstack/react-query'
import { group } from 'd3-array'
import { useContext, useMemo } from 'react'
import { MastData, MastsAPI } from '../../client'
import MastContext from '../../contexts/MastContext'
import { formatDateWithoutTime } from '../../utils/formatDate'

export const DEFAULT_DATA = { mastData: [] as MastData[] }
const CACHE_KEY_MAST_DATA = 'CACHE_KEY_MAST_DATA'
export const QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}
const DT_NOW = new Date()

const useData = (dtIn: Date, dtFin: Date) => {
  const { mast, isLoading: mastIsLoading } = useContext(MastContext)

  const { data: mastData, isFetching: mastDataIsFetching } = useQuery(
    [CACHE_KEY_MAST_DATA, mast.id, dtIn.toISOString(), dtFin.toISOString()],
    () =>
      MastsAPI.getMastData({
        mastId: mast.id,
        dateIn: formatDateWithoutTime(dtIn),
        dateFin: formatDateWithoutTime(dtFin),
      }),
    {
      ...QUERY_OPTIONS,
      placeholderData: DEFAULT_DATA.mastData,
      enabled: mast.id !== 0 && dtFin < DT_NOW,
    }
  )
  const mastDataBySensor = useMemo(() => {
    if (!mastData) return group([] as MastData[], m => m.sensor_id)
    return group(mastData, m => m.sensor_id)
  }, [mastData])

  return {
    dataIsLoading: mastIsLoading || mastDataIsFetching,
    mastData: mastData ?? DEFAULT_DATA.mastData,
    mastDataBySensor,
  }
}

export default useData
