import { useEffect, useState } from 'react'
import PlotWindRose from './PlotWindRose'

export function usePlotWindRose(revision: number) {
  const [isPlotWindRoseRender, setIsPlotWindRoseRender] = useState(false)

  useEffect(() => {
    setIsPlotWindRoseRender(false)
  }, [revision])

  const PlotWindRoseRender = () => {
    return <PlotWindRose revision={revision} onRender={() => setIsPlotWindRoseRender(true)} />
  }

  return {
    isPlotWindRoseRender,
    PlotWindRoseRender,
  }
}
