import { Divider, Grid, GridItem, Panel, PanelHeader, PanelMain } from '@patternfly/react-core'
import { CSSProperties, useContext, useEffect, useState } from 'react'
import { MastIngestionSummary, MastsAPI, SignalTypes } from '../../../client'
import EntityTable from '../../../components/EntityTable'
import MastContext from '../../../contexts/MastContext'
import useVertical from '../../../hooks/useVertical'
import { queryClient } from '../../../services/api'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { fmtDateStringToBRLDateString, formatDateWithoutTime } from '../../../utils/formatDate'
import Page from '../../shared/Page'
import LoaderSpinner from '../../shared/Page/LoaderSpinner'
import useData, { QUERY_OPTIONS } from '../useData'
import PlotSignal from './PlotSignal'
import { usePlotWindRose } from './usePlotWindRose'

const panelStyle: CSSProperties = {
  height: calcVerticalHeight({ gridSize: 1, elementSize: 1, pgHasFilter: false }),
  width: '100%',
}

const panelMainStyle: CSSProperties = {
  height: calcVerticalHeight({ gridSize: 1, elementSize: 1, pgHasFilter: true }),
  overflowY: 'auto',
}

const fmtPercentage = (n: number) => {
  return String(Number(n.toFixed(2)) * 100)
}

const MastsSummary = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const [revision, setRevision] = useState(1)
  const {
    summaries,
    isLoading: summaryIsLoading,
    setActiveMast,
    mast,
    allMasts,
    sensors,
    signalTypes,
  } = useContext(MastContext)

  const signalTypesOnMast: SignalTypes[] = []
  signalTypes.forEach(st => {
    sensors.forEach(s => {
      if (st.type === s.type && !signalTypesOnMast.some(item => item.type === st.type)) {
        signalTypesOnMast.push(st)
      }
    })
  })

  useEffect(() => setRevision(revision + 1), [mast, isNavOpen])

  const tsFin = new Date(mast.dataset_end)
  const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)
  const { dataIsLoading } = useData(tsIn, tsFin)

  const handleSelectSummary = (mastSummary: MastIngestionSummary) => {
    const _mastIndex = allMasts.findIndex(m => m.id === mastSummary.mast_id)
    const _mast = allMasts[_mastIndex]
    setActiveMast(_mast)
    const mastAnterior = allMasts[_mastIndex - 1]
    const mastProximo = allMasts[_mastIndex + 1]

    if (mastAnterior) {
      const tsFin = new Date(mastAnterior.dataset_end)
      const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)
      prefetchGetMastData(mastAnterior.id, tsIn, tsFin)
    }
    if (mastProximo) {
      const tsFin = new Date(mastProximo.dataset_end)
      const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)
      prefetchGetMastData(mastProximo.id, tsIn, tsFin)
    }
  }

  const [refetchProximoInit, setRefetchProximoInit] = useState(false)
  useEffect(() => {
    if (!refetchProximoInit && allMasts?.length > 0 && mast?.id && summaries?.length > 0) {
      const _summaryInicial = summaries.find(s => s.mast_id === mast.id)
      const _mastIndex = allMasts.findIndex(m => m.id === _summaryInicial?.mast_id)
      const mastProximo = allMasts[_mastIndex + 1]
      if (mastProximo) {
        const tsFin = new Date(mastProximo.dataset_end)
        const tsIn = new Date(tsFin.getFullYear(), tsFin.getMonth(), tsFin.getDate() - 7)
        prefetchGetMastData(mastProximo.id, tsIn, tsFin)
      }
      setRefetchProximoInit(true)
    }
  }, [JSON.stringify(allMasts), mast?.id, JSON.stringify(summaries)])

  const { PlotWindRoseRender, isPlotWindRoseRender } = usePlotWindRose(revision)

  return (
    <Page
      pageName='Resumo das Torres'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={summaryIsLoading}
      isContentLoading={dataIsLoading}
    >
      <Grid hasGutter lg={4}>
        <GridItem>
          <Panel style={panelStyle} className='pf-v5-u-box-shadow-md'>
            <PanelHeader>
              <b>Torres</b>
            </PanelHeader>
            <Divider />
            <PanelMain>
              <EntityTable<MastIngestionSummary>
                items={summaries}
                isLoading={summaryIsLoading}
                itemKeyName='mast_id'
                selected={summaries.find(s => s.mast_id === mast.id)}
                onSelect={handleSelectSummary}
                isCompact
                columnConfig={[
                  { key: 'mast_name', description: 'Nome' },
                  {
                    key: 'dados_ate',
                    description: 'Dados Até',
                    formatter: fmtDateStringToBRLDateString,
                  },
                  { key: 'dias_sem', description: 'Dias Sem' },
                  { key: 'cobertura', description: 'Cobertura', formatter: fmtPercentage },
                ]}
              />
            </PanelMain>
          </Panel>
        </GridItem>
        <GridItem lg={8}>
          <Panel style={panelStyle} className='pf-v5-u-box-shadow-md'>
            <PanelHeader>
              <b>Medições</b>
            </PanelHeader>
            <Divider />
            {(dataIsLoading || !isPlotWindRoseRender) && <LoaderSpinner />}
            {!dataIsLoading && (
              <PanelMain style={panelMainStyle}>
                <Grid style={{ ...(!isPlotWindRoseRender && { height: 0 }) }}>
                  <GridItem span={6} rowSpan={2}>
                    <PlotWindRoseRender />
                  </GridItem>
                  {signalTypesOnMast.map((sigType, i) => (
                    <GridItem key={`${sigType.id}-${i}`} lg={6}>
                      <PlotSignal signalType={sigType} revision={revision} />
                    </GridItem>
                  ))}
                </Grid>
              </PanelMain>
            )}
          </Panel>
        </GridItem>
      </Grid>
    </Page>
  )
}

const prefetchGetMastData = (mastId: number, tsIn: Date, tsFin: Date) => {
  queryClient.prefetchQuery(
    ['CACHE_KEY_MAST_DATA', mastId, tsIn.toISOString(), tsFin.toISOString()],
    () =>
      MastsAPI.getMastData({
        mastId,
        dateIn: formatDateWithoutTime(tsIn),
        dateFin: formatDateWithoutTime(tsFin),
      }),
    QUERY_OPTIONS
  )
}

export default MastsSummary
